import React from "react";
import { graphql } from "gatsby";
// import ReactMarkdown from "react-markdown";

import Layout from "../components/layout"
import SelectCategory from "../components/common/selectCategory";
import IntroText from "../components/common/introText";
import AllPost from "../components/common/allPost";

export const query = graphql`
  query Category($slug: String!) {
    categoryPost: allStrapiBlogs(
        filter: {categories: {elemMatch: {slug: { eq: $slug }}}}
      ) {
        nodes {
          Title
          Subtitle
          ourpicks
          slug
          FeaturedPost
          blogImage{
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Description
          categories {
            slug
            CategoryDescription
            CategoryName
          }
        }
      }
      categoryDetails: allStrapiCategories(filter: {slug: {eq: $slug}}) {
        edges {
          node {
            slug
            CategoryDescription
            CategoryName
            blogItemBottomContent
            blogItemTopContent
            SEO {
              metaDescription
              metaTitle
              structuredData
            }
            updated_at
            published_at
          }
        }
      }
  }
`;

const Category = ({ data }) => {
  const category = data;
  const blogList = category.categoryPost.nodes
  const { 
    CategoryName, 
    CategoryDescription, 
    slug, 
    // blogItemBottomContent, 
    // blogItemTopContent 
  } = category.categoryDetails.edges[0].node
  return (
    <Layout title="Category" categoryData={category.categoryDetails.edges[0].node}>
      <div className="categoryContainer blogContainer">
        <SelectCategory categoryValue={CategoryName} />
        <IntroText Title={CategoryName} Description={CategoryDescription} />
        {/* todo */}
        {/* <ReactMarkdown className="categoryDesc">
          {blogItemTopContent}
        </ReactMarkdown> */}
        {blogList.length ? <AllPost blogList={blogList} CategoryName={CategoryName} categoryPage={true} categorySlug={slug} /> : null}
        {/* <ReactMarkdown className="categoryDesc">
          {blogItemBottomContent}
        </ReactMarkdown> */}
      </div>
    </Layout>
  );
};

export default Category;
