import React from "react";
import ReactMarkdown from "react-markdown"

const IntroText = (props) => {
  const { Description, Title, Intro_text = true } = props
  return (
    <div className="d-flex justify-content-center blogContainer">
      {Intro_text === true ? (
        <div className="skipContent">
          <h1>{Title}</h1>
          <div className="d-flex justify-content-center mt-4">
            <div className="sellVideoText d-flex text-center">
              <ReactMarkdown>
                {Description}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      ) : ""
      }
    </div>
  )
}
export default IntroText