import React, { useEffect, useState } from "react"
import { slice, concat } from "lodash"
import _kebabCase from "lodash/kebabCase"
import Img from "gatsby-image"
import { Link } from "gatsby"

import ButtonField from "./form/ButtonField"

const AllPost = props => {
  const blogList = props.blogList
  const LENGTH = blogList.length
  const DATA = blogList
  const LIMIT = 9
  const [showMore, setShowMore] = useState(true)
  const [list, setList] = useState(slice(DATA, 0, LIMIT))
  const [index, setIndex] = useState(LIMIT)
  const { CategoryName = "", categoryPage = false, categorySlug = "" } = props
  useEffect(() => {
    if (LENGTH <= LIMIT) {
      setShowMore(false)
    }
  }, [LENGTH])
  const loadMore = () => {
    let newIndex = index + LIMIT
    let newShowMore = newIndex < LENGTH
    let newList = concat(list, slice(DATA, index, newIndex))
    setIndex(newIndex)
    setList(newList)
    setShowMore(newShowMore)
  }
  return (
    <div className="d-flex flex-column max2000 justify-content-center pb-5">
      <div className="allPostContainer d-flex justify-content-center row row-cols-3">
        {list.map((val, i) => {
          return (
            <div key={i} className="allPostBox d-flex flex-column col">
              <Link
                to={`/blog/${_kebabCase(
                  val.slug.toLowerCase()
                )}/`}
              >
                <Img
                  fluid={val.blogImage.localFile.childImageSharp.fluid}
                  className="allPostCardImages cursor-pointer"
                  alt={val.blogImage?.alternativeText}
                />
              </Link>
              <div className="m-2">
                <div className="d-flex align-items-center cursor-pointer">
                  <Link
                    className="allPostHeadings border-transperent"
                    to={`/blog/${_kebabCase(
                      val.slug.toLowerCase()
                    )}/`}
                  >
                    {val.Title}
                  </Link>
                  <Link
                    className="categoryBox btnHover ml-2"
                    to={`/${_kebabCase(
                      (categoryPage
                        ? categorySlug
                        : val.categories[0].slug
                      ).toLowerCase()
                    )}/`}
                  >
                    {categoryPage
                      ? CategoryName
                      : val.categories[0].CategoryName}
                  </Link>
                </div>
                <Link
                  className="allPostTexts mt-2 cursor-pointer"
                  to={`/blog/${_kebabCase(
                    val.slug.toLowerCase()
                  )}/`}
                >
                  {val.Subtitle}
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div className="text-center">
        {showMore && (
          <ButtonField
            className="LoadMoreBtn my-5"
            onClick={() => loadMore()}
            name="LOAD MORE"
          />
        )}
      </div>
    </div>
  )
}

export default AllPost
